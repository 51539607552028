var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"grid gap-4"},[_c('BaseHeading',{staticClass:"text-lg font-bold",attrs:{"level":2}},[_vm._v(" Lista de contactos ")]),_c('BaseBox',{staticClass:"bg-white dark:bg-gray-800 shadow rounded-md overflow-hidden px-6 py-8"},[_c('BaseInputText',{staticClass:"mb-5",attrs:{"placeholder":"Filtrar por nombre, compañía, correo o mensaje "},model:{value:(_vm.model.searchBy),callback:function ($$v) {_vm.$set(_vm.model, "searchBy", $$v)},expression:"model.searchBy"}}),_c('BaseTable',{attrs:{"mode":"remote","total-rows":_vm.totalItems,"is-loading":_vm.isLoading,"pagination-options":{
        enabled: true,
        perPage: _vm.model.rowsPerPage,
      },"sort-options":{
        enabled: false,
      },"rows":_vm.contactList,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
      var column = ref.column;
      var formattedRow = ref.formattedRow;
      var row = ref.row;
return [(column.field === 'creationDate')?_c('span',[_c('BaseDate',{attrs:{"date":row.creationDate}})],1):_c('span',[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }