import {
  ContactModel,
  contactService,
} from '@/services/modules/contact/contact'
import { computed } from '@vue/composition-api'
import { useServicePaginated } from './useServicePaginated'

export function useContact(model: ContactModel) {
  const request = computed<ContactModel>(() => ({
    ...model,
  }))
  return useServicePaginated(request, () => contactService.get(request.value))
}
