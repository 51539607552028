












































import { useContact } from '@/composition/useContact'
import {
  ContactModel,
  ContactViewModel,
} from '@/services/modules/contact/contact'
import { defineComponent, reactive } from '@vue/composition-api'

import 'vue-good-table/dist/vue-good-table.css'
import {
  VueGoodTableColumn,
  VueGoodTablePageChangeEvent,
  VueGoodTablePerPageChangeEvent,
} from '@/setup/vue-good-table'

export default defineComponent({
  name: 'Contact',
  setup() {
    const model = reactive<ContactModel>({
      pageNumber: 1,
      rowsPerPage: 10,
      searchBy: null,
      startingDate: null,
      endingDate: null,
    })

    const {
      data: contactList,
      totalItems: totalItems,
      isLoading: isLoading,
      error: error,
    } = useContact(model)

    const columns: VueGoodTableColumn<ContactViewModel>[] = [
      {
        label: 'Nombre',
        field: 'name',
      },
      {
        label: 'Compañía',
        field: 'company',
      },
      {
        label: 'Correo',
        field: 'email',
      },
      {
        label: 'Mensaje',
        field: 'message',
      },
      {
        label: 'Fecha',
        field: 'creationDate',
      },
    ]

    function onPageChange(newParams: VueGoodTablePageChangeEvent) {
      model.pageNumber = newParams.currentPage
    }

    function onPerPageChange(newParams: VueGoodTablePerPageChangeEvent) {
      model.rowsPerPage = newParams.currentPerPage
    }

    return {
      contactList,
      model,
      isLoading,
      totalItems,
      error,
      columns,
      onPageChange,
      onPerPageChange,
    }
  },
})
