import { AxiosInstance } from 'axios'
import { api } from '../../client'
import { PaginationList, PaginationSelectModel, serviceUrl } from '../../utils'

const controller = serviceUrl('contact')

function createContactService(client: AxiosInstance) {
  return {
    insert(data: ContactInsertViewModel) {
      return client.post<void>(controller.action('insert'), data)
    },
    get(params: ContactModel) {
      return client.get<PaginationList<ContactViewModel>>(
        controller.action('get'),
        {
          params,
        }
      )
    },
  }
}

export const contactService = createContactService(api.base)

export interface ContactInsertViewModel {
  name: string
  company: string
  email: string
  message: string
}

export interface ContactModel extends PaginationSelectModel {
  startingDate?: Date | null
  endingDate?: Date | null
}

export interface ContactViewModel {
  contactId: string
  contactKey: number
  name: string
  company: string
  email: string
  message: string
  creationDate: string
  rowCount: number
}
